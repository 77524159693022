const axios = require('axios');
// axios.defaults.baseURL = "/api";//测试
axios.defaults.baseURL = "https://lndx.zsglxt.cn/";//服务器

import vm from './../main';

class ajax {

	constructor() {}

	get(argus) {
		//TODO
		axios.get(argus.url ? argus.url : '', {
			params: {
				...argus.data
			},
			headers: {
				token: localStorage.getItem("token_user") ? localStorage.getItem("token_user") : ''
			}
		}).then(res => {
			this.affterRequest(res, argus);
		})
	}

	post(argus) {
		//TODO
		// alert(axios.defaults.baseURL + argus.url);
		axios.post(argus.url ? argus.url : '', {
			...argus.data
		}, {
			headers: {
				'token': localStorage.getItem("token_user") ? localStorage.getItem("token_user") : ''
			}
		}).then(res => {
			this.affterRequest(res, argus);
		})
	}
	filePost(argus) {
		//TODO
		axios.post(argus.url ? argus.url : '', {
			...argus.data
		}, {
			headers: {
				'token': localStorage.getItem("token_user") ? localStorage.getItem("token_user") : '',
				'Content-Type': 'multipart/form-data'
			}
		}).then(res => {
			this.affterRequest(res, argus);
		})
	}
	// 错误弹框
	errorToast(e) {
		vm.$message.error(e);
	}
	// 成功弹框
	successToast(e) {
		console.log(e)
		vm.$message.success(e);
	}
	/*请求之后*/
	affterRequest(res, argus) {
		if (typeof(argus.test) != 'undefined') {
			argus.test(res);
		} else {
			if (res.data.code == 200) {
				argus.success(res.data);
			} else {
				if (typeof(argus.errDeal) != 'undefined') {
					argus.errDeal(res.data);
				} else {
					this.errorToast(res.data.msg)
				}
			}
		}
	}
	/*正则验证手机号*/
	check_phone(phone) {
		if (!(/^1[3456789]\d{9}$/.test(phone))) {
			return false;
		} else {
			return true;
		}
	}

}

export default ajax;
