import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//核心：解决代码冗余
const routerReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location) {
  return routerReplace.call(this, location).catch(error => error)
}
const routes = [
  {path: '/',redirect:'/login'},
  {path: '/login',name: 'login',component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue')},
  {path: '/forgot',name: 'forgot',component: () => import(/* webpackChunkName: "forgot" */ '../views/forgot/forgot.vue')},
  {path: '/register',name: 'register',component: () => import(/* webpackChunkName: "register" */ '../views/register/register.vue')},
  {path: '/privacy',name: 'privacy',component: () => import(/* webpackChunkName: "privacy" */ '../views/privacy/privacy.vue')},
	{
	  path: '/home',
		name: 'home',
		component: () => import(/* webpackChunkName: "home" */ '../views/home/home.vue'),
		children:[
			{path: '/home/',name: 'mine',component: () => import(/* webpackChunkName: "mine" */ '../views/mine/mine.vue')},
			{path: '/home/user',name: 'user',component: () => import(/* webpackChunkName: "user" */ '../views/user/user.vue')},
			{path: '/home/password',name: 'password',component: () => import(/* webpackChunkName: "password" */ '../views/password/password.vue')},
			{path: '/home/phone',name: 'phone',component: () => import(/* webpackChunkName: "phone" */ '../views/phone/phone.vue')},
			{path: '/home/curriculum',name: 'curriculum',component: () => import(/* webpackChunkName: "curriculum" */ '../views/curriculum/curriculum.vue')},
			{path: '/home/CourseDetails',name: 'CourseDetails',component: () => import(/* webpackChunkName: "CourseDetails" */ '../views/curriculum/CourseDetails.vue')},
			{path: '/home/CourseRegistration',name: 'CourseRegistration',component: () => import(/* webpackChunkName: "CourseRegistration" */ '../views/curriculum/CourseRegistration.vue')},
			{path: '/home/CourseConfirmation',name: 'CourseConfirmation',component: () => import(/* webpackChunkName: "CourseConfirmation" */ '../views/curriculum/CourseConfirmation.vue')},
			{path: '/home/record',name: 'record',component: () => import(/* webpackChunkName: "record" */ '../views/record/record.vue')},
			{path: '/home/recordDetails',name: 'recordDetails',component: () => import(/* webpackChunkName: "recordDetails" */ '../views/record/recordDetails.vue')},
			{path: '/home/statement',name: 'statement',component: () => import(/* webpackChunkName: "statement" */ '../views/statement/statement.vue')},
			{path: '/home/timetable',name: 'timetable',component: () => import(/* webpackChunkName: "timetable" */ '../views/timetable/timetable.vue')},
		]
	}

]


const router = new VueRouter({
mode: 'hash',
base: process.env.BASE_URL,
  routes
})

export default router
